<template>
  <div>
    <div id="head">
      <el-card class="box-card" body-style="padding:10px">
        <div style="height: 80px;width: 100%;text-align: left">
          <div v-if="this.app" style="display: flex;">
            <div id="icon" style="width: 8%">
              <img :src="this.app.icon" style="margin-top: 0px;margin-left: 5px">
            </div>
            <div id="headRight" style="width: 100%;
              text-align: left;
              line-height: 80%;
              margin-top: 10px;
              font-size: 150%">
              <span style="">程序名: <span>{{ this.app.name }}</span></span>
              <span style="margin-left: 10%">版本: <span>{{ this.app.version }}</span></span>
              <span style="margin-left: 10%">作者: <span>{{ this.app.author }}</span></span>
              <p style="font-size: 90%">描述: <span
                  style="text-decoration: underline;color: #2c3e50">{{ this.app.describe }}</span></p>
            </div>
          </div>

        </div>
      </el-card>
    </div>
    <div style="display: flex">

      <div id="aside" style="width: 20%;margin-top: 3px;">

        <el-card style="width: 100%" class="box-card" body-style="padding:10px">

          <div style="width: 100%; height: 587px">

            <el-row class="tac" style="width: 100%;">
              <el-col>
                <h1 class="mb-2" style="font-size: 150%;
                color: white;height: 35px;
                background: #939393"><span style="">功能模块</span></h1>
                <el-scrollbar height="510px">
                  <el-menu
                      active-text-color="#ffd04b"
                      background-color="#545c64"
                      class="el-menu-vertical-demo"
                      default-active="2"
                      text-color="#fff"
                      @open="handleOpen"
                      @close="handleClose"
                  >
                    <el-sub-menu :index="module.id" :id="module.id" v-for="module in modules">
                      <template #title>
                        <el-icon>
                          <icon-menu/>
                        </el-icon>
                        <span style="color:#FDD04C;">{{ module.name }}</span>
                      </template>
                      <el-menu-item :id="func.id" v-for="func in module.functions"
                                    @click="getFunctionDescribe(func.id)">{{ func.name }}
                      </el-menu-item>
                    </el-sub-menu>
                  </el-menu>
                </el-scrollbar>
              </el-col>
            </el-row>
          </div>

        </el-card>
      </div>
      <div style="margin-left: 5px;margin-top:5px;width: 100%">
        <el-card body-style="background: #FABF6D;padding:10px">
          <el-scrollbar height="585px">
            <div id="content" style="width:100%;height: 585px;text-align: left;background: #D9FADC">
              <div style="margin-left: 30%;">
                <p v-for="o in 5" style="color:#D9FADC;"> a</p>
                <span style="margin-top: 20%;font-size: 150%">获取方式: 作者邮箱 qh156256356@outlook.com <br/>发送内容: 程序名</span>
              </div>

            </div>
          </el-scrollbar>

        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import notice from "@/utils/notice";
import router from "@/router";

export default {
  name: "Product",
  data() {
    return {
      height: "585px",
      modules: [],
      app: null,
      body: null
    }
  },
  methods: {
    getAppInfo() {
      var number = location.href.lastIndexOf("/");
      let appId = location.href.substring(number + 1);
      request("/db/product/application/info/" + appId).then(res => {
        if (res.code === 200) {
          this.app = res.data;
          this.modules = this.app.modules;
          return;
        }
        if (res.code !== 200) {
          notice.notice(res);
        }
        if (res.code === 201) {
          var time = setInterval(() => {
            if (this.app) {
              clearInterval(time);
            } else {
              request.post("/db/product/application/info/" + appId).then(res => {
                if (res.code !== 200) {
                  notice.notice(res);
                  return;
                }
                this.app = res.data;
                this.modules = this.app.modules;
                clearInterval(time);
              })
            }
          }, 998);

        } else {
          router.push("/")
        }

      }).catch(res => {
      });
    },
    getFunctionDescribe(functionId) {
      request("/db/product/functionDescribe/info/" + functionId).then(res => {
        if (res.code === 200) {
          this.body = res.data;
          this.mountBody();
          return;
        }
        if (res.code !== 200) {
          notice.notice(res);
        }
        if (res.code === 201) {
          var time = setInterval(() => {
            request.post("/db/product/application/info/" + appId).then(res => {
              if (res.code !== 200) {
                notice.notice(res);
                return;
              }
              this.body = res.data;
              this.mountBody();
              clearInterval(time);
            })
          }, 998);
        }
      }).catch(res => {
      });
    },
    mountBody() {
      $("#content").empty()
      $("#content").append($(this.body.content)[0])
      // $("#content").append($(this.body.content).css("height", this.height)[0])
    }
  },
  created() {
    this.getAppInfo();
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>