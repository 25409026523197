import { createRouter, createWebHistory } from 'vue-router'
import MahjView from '@/views/MahjView.vue';
import HomeView from '../views/HomeView.vue'
import Product from "@/views/ProductView.vue";
import PlayerView from "@/views/PlayerView.vue";
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/product/:appId',
    name: 'product',
    component: Product
  },
  {
    path: '/play',
    name: 'player',
    component: PlayerView
  },
  {
    path: '/product/mahj',
    name: 'MarkdownViewer',
    component: MahjView,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
