<template>
  <el-container>
    <el-header>
      <MahjHead/>
    </el-header>
    <el-container>
      <el-aside width="15%" style=" overflow-y: auto;">
        <MahjSide :body="body"/>
      </el-aside>
      <el-main style="height: calc(98.5vh - 64px); " >
        <el-scrollbar ref="mainScrollbarRef" always @scroll="scroll">
        <MahjBody ref="body" style="height: 80%;" :mainScrollbarRef="mainScrollbarRef"/>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>

</template>

<script>
import MahjBody from "@/components/mahj/MahjBody.vue"
import MahjSide from "@/components/mahj/MahjSide.vue"
import MahjHead from "@/components/mahj/MahjHead.vue"

export default {
  components: {
    MahjBody,
    MahjSide,
    MahjHead
  },
  data() {
    return {
      body: null,
      mainScrollbarRef: null,
    }
  },
  mounted() {
    this.body = this.$refs.body;
    this.mainScrollbarRef = this.$refs.mainScrollbarRef;
  }
};
</script>

<style scoped>
/* 添加样式（可选） */
</style>
