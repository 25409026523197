import {ElMessage} from "element-plus";

export default {
    notice(res) {
        if (res.code === 200) {
            ElMessage({
                message: res.msg,
                type: 'success',
            });
        } else ElMessage.error(res.msg);
    }
}
