<template>

  <el-menu style="font-weight: 2; border: none; margin-top: 10%">
    <el-menu-item index="1" @click="gotoIntroductionVideo" style="font-size: 100%">视频介绍</el-menu-item>
    <el-menu-item index="3" @click="gotoLoginOrRegister()" style="font-size: 100%">登入/注册模块</el-menu-item>
    <el-menu-item index="4" @click="gotoHome()" style="font-size: 100%">主界面</el-menu-item>
    <el-menu-item index="5" @click="gotoRoom()" style="font-size: 100%">多人对战房间</el-menu-item>
    <el-menu-item index="6" @click="gotoBattlefield()" style="font-size: 100%">对战界面</el-menu-item>
    <el-menu-item index="7" @click="gotoProjectUrl()" style="font-size: 100%">项目部署地址</el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "MahjSide",
  props: {
    body: {
      type: Object,
      request: true
    }
  },
  methods: {
    gotoIntroductionVideo() {
      if (this.body) {
        this.body.gotoIntroductionVideo()
      }
    },
    gotoLoginOrRegister() {
      if (this.body) {
        this.body.gotoLoginOrRegister()
      }
    },
    gotoHome() {
      if (this.body) {
        this.body.gotoHome()
      }
    },
    gotoRoom() {
      if (this.body) {
        this.body.gotoRoom()
      }
    },
    gotoBattlefield() {
      if (this.body) {
        this.body.gotoBattlefield()
      }
    },
    gotoProjectUrl() {
      if (this.body) {
        this.body.gotoProjectUrl()
      }
    }

  },
};
</script>
<style>

</style>