<template>
  <h2>Mahjong Project</h2>
  <hr/>
  <div >
    <h3>介绍视频</h3>
    <div style="margin: 0 auto;width: 400px">
      <video
          ref="videoPlayer"
          :src="videoSrc"
          width="400"
          controls
          @play="onPlay"
          @pause="onPause"
          @ended="onEnded"
      >
      </video>
    </div>

    <!--<div>-->
    <!--  <button @click="playVideo">播放</button>-->
    <!--  <button @click="pauseVideo">暂停</button>-->
    <!--</div>-->
  </div>
  <h3>登入/注册模块</h3>
  <ul>
    <li>注册
      <ul>
        <li>手机号码：要求1-9开头11位数字。</li>
        <li>密码：6-16位数字+字母组合。</li>
        <li>昵称：非空字符。</li>
        <li>验证码：必须符合验证图片内容。</li>
      </ul>
    </li>
  </ul>
  <div>
    <img src="product/images/mahj/register.png"/>
    <p>注册界面</p>
  </div>

  <ul>
    <li>登入
      <ul>
        <li>手机号码：必须是已经注册成功的手机号。</li>
        <li>密码：注册账号时填入的密码。</li>
        <li>验证码：必须符合验证图片内容。</li>
      </ul>
    </li>
  </ul>
  <div>
    <img src="product/images/mahj/login.png"/>
    <p>登入界面</p>
  </div>

  <h3>主界面</h3>
  <div>
    <img src="product/images/mahj/home.png"/>
    <p>主界面</p>
  </div>

  <ul>
    <li>对战方式
      <ul>
        <li>人机练习：玩家可以和机器人进行对战练习，但战绩不会被记录。</li>
        <li>多人对战：玩家可以开房间邀请其他玩家一起进行对战，每局(一局可以有1到多场)完成后会记录本场战斗的战绩。</li>
      </ul>
    </li>
    <li>开房间选项：会创建一个全新的多人人竞技房间。</li>
    <li>进入房间选项：输入房间的id进入指定的房间。</li>
    <li>随机匹配选项：随机进入一个允许匹配的多人竞技房间。</li>
    <li>对局记录：记录了玩家参与的一局游戏最后的结算记录。</li>
    <li>
      金币：对战输赢的结算单位，人人对战每局开始都会给与每位参与者1000金币(当局对战有效)。人机对战输赢的金币会显示在主界面。
    </li>
    <li>修改资料：玩家每天可以修改3次自己的呢称和头像。</li>
    <li>安全退出：退出玩家的登入状态。</li>
  </ul>
  <h3>多人对战房间</h3>
  <div>
    <img src="product/images/mahj/home.png"/>
    <p>房间界面</p>
  </div>

  <ul>
    <li>房间号：一个多人竞技房间的唯一标识符。</li>
    <li>房间状态：点击(进制匹配/允许匹配)进行切换当前房间是否允许匹配的状态。</li>
    <li>准备按钮：点击准备按钮表示玩家已经准备就绪。</li>
    <li>交换位置：玩家之间进行位置交换，如果目标位置存在玩家，则需要目标位置玩家同意才可以进行位置交换。</li>
    <li>退出房间按钮：点击退出房间。</li>
  </ul>
  <h3>对战界面</h3>
  <div>
    <img src="product/images/mahj/home.png"/>
    <p>对战界面</p>
  </div>

  <ul>
    <li>提示板展示了当前可以还可以抓多少张牌，两次投掷骰子的点数和被翻出的牌。</li>
    <li>玩家等级，当玩家积累对战经验即可提升等级。</li>
    <li>余额：展示各个玩家剩下多少余额，机器人每场余额默认500。</li>
    <li>开始游戏：
      <ul>
        <li>人机对战时用户点击开始游戏按钮则可开启新一轮对战。</li>
        <li>多人对战时，当所有玩家都准备完成后，庄家可点击开始游戏按钮，开启新一轮对战。</li>
      </ul>
    </li>
    <li>安全退出按钮： 当一轮对战开始后，可以点击安全退出按钮，退出本次对战。</li>
    <li>吃按钮：当上家打出牌后可以点击吃按钮进行吃牌，后选择使用什么牌进行吃牌。</li>
    <li>碰按钮：当其他玩家出牌后，可以进行碰牌操作。</li>
    <li>杠按钮：当自己抓到或其他玩家出牌后，可进行杠牌操作。</li>
    <li>过按钮，当其他玩家出牌后询问当前玩家是否有吃，碰或杠操作时会等待玩家，点击过按钮可以结束玩家的询问时间。</li>
    <li>延时10秒按钮，可以在询问操作过程中，延迟10秒的时间用于思考。。</li>
    <li>胡按钮，表示自己胡牌了。</li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      videoSrc: '/product/videos/mahj.mp4', // 替换为您的视频URL
    };
  },
  methods: {
    playVideo() {
      this.$refs.videoPlayer.play();
    },
    pauseVideo() {
      this.$refs.videoPlayer.pause();
    },
    onPlay() {
      console.log('视频正在播放');
    },
    onPause() {
      console.log('视频已暂停');
    },
    onEnded() {
      console.log('视频播放结束');
    },
  },
};
</script>

<style scoped>
/* 添加样式（可选） */
</style>
