<template>
  <div style="display: flex">

    <img :src="images.icon" width="3%">
    <span style="font-size: 120%; margin-top: 20px; margin-left: 20px">{{ name }}</span>
    <!--<div class="head">-->
    <!--  <span style="margin-left: 20%">项目名: {{ name }}</span>-->
    <!--  <span style="margin-left: 40%">版本: {{ version }}</span> <br/>-->
    <!--  <span style="margin-left: 7%">作者: {{ author }}</span>-->
    <!--  <span style="margin-left: 25%">描述:-->
    <!--    <span style="font-size: 80%">{{ description }}</span>-->
    <!--  </span>-->
    <!--</div>-->
  </div>
  <hr/>
</template>

<script>
export default {
  name: "MahjHead",
  data() {
    return {
      images: {
        "icon": "/product/images/mahj/mahj.ico",
      },
      name: "Mahjong Project",
      version: "1.0.0",
      author: "qihe",
      description: "一款基于Vue+Java前后端分离式麻将竞技系统，支持人机和人人两种竞技方式"
    }
  }
}
</script>

<style scoped>
.head {
  //display: flex;
  //flex-direction: column; /* 垂直排列 */
  font-size: 125%;
  width: 100%;
}
</style>